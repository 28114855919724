import React from "react";
import UserLogo from '../../../../images/rewampimages/user.png'
import { GeneratePaymentLink, amiAmountReakupApi, getAmountPaymentApi, getLoanDetailByUserIdOptimised, getSourceOfEnach } from "../AdminActionCreator.component";
import { ROLE } from "../../../../utils/Constant";
import HistoryImage from '../../../../images/rewampimages/history.png'
import Intallment from '../../../../images/rewampimages/transaction-history.png'
import Transaction from '../../../../images/rewampimages/transaction.png'
import ViewEmi from '../../../../images/rewampimages/viewemi.png'
import EmiActive from '../../../../images/rewampimages/activehistory.png'
import IntallmentActive from '../../../../images/rewampimages/transaction-historyctive.png'
import HistoryActive from '../../../../images/rewampimages/historyactive.png'
import TxnActive from '../../../../images/rewampimages/transactionactive.png'
import LoanHistory from "./laonhistory";
import Installment from "./Installment.container";
import TransactionUi from "./Transaction.container";
import EmiUi from './EmiNew.container.js'
import { APIS } from "../../../../utils/api-factory";
import Popup from "../../../presentationals/Popup/Popup.component";
import LinkPopup from '../../../presentationals/Popup/listpopup';
class LoanReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newAllDetail: '',
            loaderState: false,
            viewTab: '',
            nachSourceData: '',
            popupState: false,
            popupStatus: '',
            searchedLoanData: '',
            linkPopup: false,
            PopupData: {
                PaymentType: '',
                paymentAmount: '',
                email: true,
                sms: true,
            },
            paymentLink: '',
            PopupOverDueAmount: {},
            waivedAmount: 0,
            waivedPenalty: 0

        }
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    componentDidMount() {
        const { allDetail } = this.props
        this.getloanData()
        this.getSource()
        // this.getEmidata()

    }
    buttonHandle(data) {
        this.setState({ viewTab: data })
    }
    getloanData() {
        const { allDetail } = this.props
        getLoanDetailByUserIdOptimised(allDetail.userId, callBack => {
            if (callBack != '') {
                console.log(callBack, 'callBackcallBack')
                this.handleSearch(callBack.loanDetailList)
                let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
                tentativeDetail.tentativeAmount = callBack.loanAmount
                tentativeDetail.selectEMI = callBack.loanEmi
                this.setState({ tentativeDetail })
            }
            this.setState({

                newAllDetail: callBack,
                loaderState: false
            })
        })
    }
    handleSearch(data) {
        console.log(data)
        const { userDetail, allDetail } = this.props
        // ihavechange
        console.log(this.props)
        if (userDetail.applicationId) {
            if (data.length > 1) {
                const results = data.filter((application) =>
                    application.applicationId.toLowerCase().includes(userDetail.applicationId.toLowerCase())
                );
                this.setState({ searchedLoanData: results })
            } else {
                this.setState({ searchedLoanData: data })

            }
        } else {
            if (data.length > 1) {
                const results = data.filter((application) =>
                    application.applicationId.toLowerCase().includes(userDetail.applicationId.toLowerCase())
                );
                this.setState({ searchedLoanData: results })
            } else {
                this.setState({ searchedLoanData: data })

            }
        }

    };


    getSource() {
        const { admin, userDetail, allDetail } = this.props
        if (userDetail.nachStatus == true) {

            getSourceOfEnach(userDetail.userId, callBack => {
                this.setState({ nachSourceData: callBack })
            })
        }
    }
    calcamount() {
        const { userDetail, admin } = this.props
        const obj = {
            loanId: userDetail.loanId,
            userId: userDetail.userId,
            amount: '',
            emiId: this.state.PopupOverDueAmount.emiId,
            empId: admin.emailId,
            paymentType: this.state.PopupData.PaymentType,
            sendSms: this.state.PopupData.sms,
            sendMail: this.state.PopupData.email,

        };
        let changedata = Object.assign({}, this.state.PopupData)
        getAmountPaymentApi(obj, this.state.waivedAmount, this.state.waivedPenalty, (callback) => {
            if (callback) {
                changedata.paymentAmount = callback.amount
                this.setState({ paymentLink: callback.shortUrl, PopupData: changedata })
            }
        })
    }
    removePopup() {
        setTimeout(
            function () {
                this.setState({ popupState: false })
            }.bind(this),
            5000
        )
    }
    sendNoc(data) {
        const { admin } = this.props
        this.setState({ loaderState: true })
        fetch(APIS.SEND_NOC_API + data + '&empId=' + admin.emailId)
            .then(res => res.text())
            .then(res => {
                if (res == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Sent Sucessfully!',
                        loaderState: false
                    })
                    this.removePopup()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                        loaderState: false
                    })
                    this.removePopup()
                }
            })
    }

    handlePopupValue(data) {
        console.log(data)
        this.setState({ linkPopup: true })
    }
    getEmidata(){
        const { userDetail, admin } = this.props

        amiAmountReakupApi(userDetail.loanId,callback=>{
            console.log(callback)
            this.setState({waivedAmount:callback})

        })

    }

    GeneratePaymentLinkdata() {
        const { userDetail, admin } = this.props
        const obj = {
            loanId: userDetail.loanId,
            userId: userDetail.userId,
            amount: this.state.PopupData.paymentAmount,
            emiId: '',
            empId: admin.emailId,
            paymentType: this.state.PopupData.PaymentType,
            sendSms: this.state.PopupData.sms,
            sendMail: this.state.PopupData.email,
        };
        GeneratePaymentLink(obj, this.state.waivedAmount, this.state.waivedPenalty, (callback) => {
            if (callback) {
                this.setState({ paymentLink: callback.shortUrl })
            }
        })
    }

    handleLink(type) {
        if (type === 'close') {
            this.setState({ linkPopup: false })
        }
        if (type === 'copy') {
            var copyText = document.getElementById("linkt");
            copyText.select();
            navigator.clipboard.writeText(copyText.value);
            alert("Copied the text: " + copyText.value);

        }
    }

    changeHandler(e, type) {
        let Popupdata = Object.assign({}, this.state.PopupData)
        if (type === 'paymentType') {
            Popupdata.PaymentType = e.target.value;
            if (e.target.value === 'fullpayment') {
                Popupdata.paymentAmount = this.state.searchedLoanData[0].todayAmount;
            } else if (e.target.value === 'partpayment') {
                Popupdata.paymentAmount = '';
            }
        } else if (type === 'paymentAmount') {
            if (Popupdata.PaymentType === 'partpayment') {
                if (e.target.value < Number(this.state.searchedLoanData[0].todayAmount)) {
                    Popupdata.paymentAmount = e.target.value;
                } else {
                    Popupdata.paymentAmount = '';
                }
            }
        } else if (type === 'EMAIL') {
            Popupdata.email = e.target.checked;
        } else if (type === 'SMS') {
            Popupdata.sms = e.target.checked;
        }
        else if (type === 'waivedAmount') {
            this.setState({ waivedAmount: e.target.value })
        }
        else if (type === 'waivedPenalty') {
            this.setState({ waivedPenalty: e.target.value })
        }
        this.setState({
            PopupData: Popupdata
        })
    }


    render() {
        const { admin, userDetail, allDetail } = this.props
        return (
            <div className="container-fluid animated fadeIn " style={{ marginTop: '80px' }}>
                {this.state.popupState ? (
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
                {this.state.newAllDetail !== '' && this.state.newAllDetail.loanDetailList && this.state.newAllDetail.loanDetailList.length > 0 ?
                    <div className="details-body" style={{ marginTop: window.innerWidth > 1500 ? '5%' : '40px', marginBottom: window.innerWidth > 1500 ? '5%' : 'auto' }}>
                        
                        <div className="details-head">
                            <div className="row">
                                <div className="col-sm-6 col-xs-6"> <img src={UserLogo} style={{ width: '20px' }} />&nbsp;<b>Loan Information</b>
                                </div>
                                <div className="col-sm-6 col-xs-6 text-right">
                                    <b>NACH:</b>   {userDetail.nachStatus != null && userDetail.nachStatus != null ? (
                                        userDetail.nachStatus == true ? (
                                            <span className='darkGreen-text' style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>Active -{this.state.nachSourceData.signDesk ? 'Sign Desk' : this.state.nachSourceData.billDesk ? 'Billdesk' : this.state.nachSourceData.razorPay ? (this.state.nachSourceData.razorPay)?.toUpperCase() : this.state.nachSourceData.techProcess ? 'Techprocess' : ''}</span>
                                        ) : (
                                            <span className='red-text' style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>Inactive</span>
                                        )
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>

                        {this.state.searchedLoanData !== '' ?
                            <div style={{ padding: '10px' }}>

                                <div className="row">
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Application Id</label>
                                        <p>{this.state.searchedLoanData[0].applicationId}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Applied Date </label>
                                        <p>{this.state.searchedLoanData[0].loanApplyDate}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Product Type </label>
                                        <p>{this.state.searchedLoanData[0].productType}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Loan From </label>
                                        <p>{this.state.searchedLoanData[0].platFormFrom}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Sub Status </label>
                                        <p>{this.state.searchedLoanData[0].subStatus}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Approved Date </label>
                                        <p>{this.state.searchedLoanData[0].approveDate}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Disbursed Date </label>
                                        <p>{this.state.searchedLoanData[0].disburseDate}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>IVR Status </label>
                                        <p>{this.state.searchedLoanData[0].ivrStatus == 1
                                            ? 'Confirmed'
                                            : this.state.searchedLoanData[0].ivrStatus == 2
                                                ? 'Need Call'
                                                : null}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Applied Amount</label>
                                        <p>{this.state.searchedLoanData[0].loanAmount}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Approved Amount  </label>
                                        <p>{this.state.searchedLoanData[0].approveAmount}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Tenure  </label>
                                        <p>{this.state.searchedLoanData[0].paybackDuration}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>EMI </label>
                                        <p>{this.state.searchedLoanData[0].loanEmi}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Tentative Amount</label>
                                        <p>{this.state.searchedLoanData[0].tentativeApproveAmount}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Tentative Repayment Date  </label>
                                        <p>{this.state.searchedLoanData[0].tentativeDuration}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Complete Type  </label>
                                        <p>{this.state.searchedLoanData[0].completeType}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Incomplete Type </label>
                                        <p>{this.state.searchedLoanData[0].incompleteType}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Loan Status</label>
                                        <p>{this.state.searchedLoanData[0].adminLoanStatus}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Repayment Amount  </label>
                                        <p>{this.state.searchedLoanData[0].totalPaybackAmount}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Pay on Today  </label>
                                        <p>{this.state.searchedLoanData[0].todayAmount}</p>

                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Repayment Date </label>
                                        <p>{this.state.searchedLoanData[0].loanPaybackDate}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Payment Date </label>
                                        <p>{this.state.searchedLoanData[0].paymentDate}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Date Difference </label>
                                        <p>{this.state.searchedLoanData[0].delayCount}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Foreclose Amount </label>
                                        <p>{this.state.searchedLoanData[0].totalPaybackAmount}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Processing Fee </label>
                                        <p>{this.state.searchedLoanData[0].processingFee}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Disburse Amount </label>
                                        <p>{this.state.searchedLoanData[0].disbursmentAmt}</p>
                                    </div>
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        <label>Cibil Score </label>
                                        <p>{this.state.searchedLoanData[0].cibilScore}</p>
                                    </div>
                                    {this.state.searchedLoanData[0].approveOrRejectReason ?
                                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                            <label>Reject Reason </label>
                                            <p>{this.state.searchedLoanData[0].approveOrRejectReason}</p>
                                        </div>
                                        : ""}
                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                        {this.state.searchedLoanData[0].keyFactStatementUrl ?
                                            <a target='_blank' href={this.state.searchedLoanData[0].keyFactStatementUrl.includes('//adminsupport.micromoney.in')
                                                ? this.state.searchedLoanData[0].keyFactStatementUrl.replace('//adminsupport.micromoney.in', '//adminsupport.micromoney.in') : this.state.searchedLoanData[0].keyFactStatementUrl} style={{ padding: '3px' ,color:'#fff'}} className='btn btn-primary'>View KFS</a>
                                            : ""}
                                    </div>
                                    {admin && admin.rolelist.indexOf(ROLE.SEND_NOC) >= 0 ?
                                        <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                            {admin.rolelist.indexOf(ROLE.SEND_NOC) >= 0 ? <td>{this.state.searchedLoanData[0].adminLoanStatus === 'Paid' && userDetail.loanFrom === 'micromoney' ? <button onClick={() => this.sendNoc(this.state.searchedLoanData[0].loanId)} className='btn btn-primary'style={{color:'#fff'}}>Send NOC</button> : ""}</td> : ""}
                                        </div>
                                        : ""}
                                    {
                                        (this.state.searchedLoanData[0].adminLoanStatus === 'Disbursed' || this.state.searchedLoanData[0].adminLoanStatus === 'OnGoing' || this.state.searchedLoanData[0].adminLoanStatus === 'Payment Overdue') &&
                                            this.state.searchedLoanData[0].loanEmi == 1 ?
                                            <div className="col-sm-3 col-xs-12" style={{ marginTop: '15px' }}>
                                                <button className='btn btn-primary' style={{color:'#fff'}} onClick={() => this.handlePopupValue(this.state.searchedLoanData[0])}>Generate Link</button>
                                            </div>
                                            : ''}


                                </div>
                            </div>
                            : ""}
                        <div className="btn-group btn-grp-own-big" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', }}>
                            <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'emi' ? '#0e5768' : '#F1F7FF', color: this.state.viewTab === 'emi' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('emi')}> <img src={this.state.viewTab === 'emi' ? EmiActive : ViewEmi} style={{ width: '20px' }} />View EMI </button>
                            <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'txn' ? '#0e5768' : '#F1F7FF', color: this.state.viewTab === 'txn' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('txn')} ><img src={this.state.viewTab === 'txn' ? TxnActive : Transaction} style={{ width: '20px' }} /> View Transactions </button>
                            <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'installment' ? '#0e5768' : '#F1F7FF', color: this.state.viewTab === 'installment' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('installment')} > <img src={this.state.viewTab === 'installment' ? IntallmentActive : Intallment} style={{ width: '20px' }} />View Installments </button>
                            <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'history' ? '#0e5768' : '#F1F7FF', color: this.state.viewTab === 'history' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('history')} ><img src={this.state.viewTab === 'history' ? HistoryActive : HistoryImage} style={{ width: '20px' }} /> View History </button>
                        </div>
                    </div>
                    : ""}
                {this.state.viewTab === 'history' && this.state.newAllDetail !== '' ?
                    <LoanHistory loanData={this.state.newAllDetail.loanDetailList} admin={admin} sendNoc={this.sendNoc.bind(this)} userDetails={userDetail} />
                    : ""}
                {this.state.viewTab === 'installment' ?
                    <Installment admin={admin} userDetails={userDetail} allDetail={allDetail} />
                    : ""}
                {this.state.viewTab === 'txn' ?
                    <TransactionUi admin={admin} userDetail={userDetail} allDetail={allDetail} />
                    : ""}
                {this.state.viewTab === 'emi' ?
                    <EmiUi admin={admin} userDetail={userDetail} allDetail={allDetail} />
                    : ""}
                {this.state.linkPopup ?
                    <LinkPopup
                        calcamount={this.calcamount.bind(this)}
                        PopupOverDueAmount={this.state.PopupOverDueAmount}
                        waivedAmount={this.state.waivedAmount}
                        waivedPenalty={this.state.waivedPenalty}
                        popupType={'EmiLinkGeneratePopup'}
                        confirmationText={''}
                        handleLink={this.handleLink.bind(this)}
                        changeHandler={this.changeHandler.bind(this)}
                        GeneratePaymentLink={this.GeneratePaymentLinkdata.bind(this)}
                        PopupData={this.state.PopupData}
                        paymentLink={this.state.paymentLink}
                    />
                    : ""}
            </div>
        )
    }
}
export default LoanReport