import React, { useState } from "react";
import MainEmp from './Employee/mainEmp'
import MainDepartment from './Department/mainDepartment'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const TabsExpense = ({ pageViewHandler,admin }) => {
    const [viewState, setViewState] = useState('employee')

   
    const BackBtnStyle2 = {
        color: '#fff', 
        fontWeight: '400', 
        display: 'flex', 
        background:'#223d64',
        cursor:'pointer',
        padding: '12px 20px',
        'boxShadow': 'inset -4px -4px 4px 0px #1d252d'
    }


    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
                <div className="" onClick={(e) => pageViewHandler(e, 'landingPage')} style={BackBtnStyle2}>
                <ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back

                </div>
                <div className="" onClick={() => setViewState('employee')} style={{ borderBottom: viewState === 'employee' ? '2px solid #1eb0d2' : '', background: viewState === 'employee' ? '#1eb0d2' : '#DBE9FF', padding: viewState === 'employee' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'employee' ? '#fff' : '#000', cursor: 'pointer', width: '50%' }}>
                    Employee List

                </div>
                <div className="" onClick={() => setViewState('department')} style={{ borderBottom: viewState === 'department' ? '2px solid #1eb0d2' : '', background: viewState === 'department' ? '#1eb0d2' : '#DBE9FF', padding: viewState === 'department' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'department' ? '#fff' : '#000', cursor: 'pointer', width: '50%' }}>
                    Department List

                </div>
              
            </div>
           
             <div className="" style={{ marginTop: window.innerWidth > 1800 ? '0%' : '7%'}}>

            {viewState === 'employee' ?
                <MainEmp admin={admin}/>
                : ""}
            {viewState === 'department' ?
                <MainDepartment />
                : ""}
                </div>
        </>
    )
}
export default TabsExpense