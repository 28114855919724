import { APIS } from "../../../../utils/api-factory";
import axios from "axios";

export const getNBFCList = (callBack,error) =>{
    axios.get(APIS.GET_LIST_OF_NBFC)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getMonthWiseOverdueStatsAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_MONTH_WISE_OVERDUE_STATS + sDate + "&eDate=" + eDate  +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getMonthWiseOverdueStatsDetailedAPI = (sDate,eDate,month,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_MONTH_WISE_OVERDUE_STATS_DETAILED + sDate + "&eDate=" + eDate + "&monthName=" +month  +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getDueOverDueCountsAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_DUE_OVERDUE_COUNTS + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getInvoicedPaidAmountMonthlyAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_INVOICED_PAID_AMOUNT_MONTHLY + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getOverDueAgeSummaryAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_OVERDUE_AGE_SUMMARY + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}


export const getKPILoanDashboardStatsAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_KPI_LOAN_DASHBOARD_STATS + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getLoanAmountTrendsGraphAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_LOAN_AMOUNT_TRENDS_GRAPH + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getYearWiseLoanAmountTrendsAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_YEAR_WISE_LOAN_AMOUNT_GRAPH + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getUpcomingDuePaymentAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_UPCOMING_DUE_PAYMENTS + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getLoanStatusWiseCountsAPI = (nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_LOAN_STATUS_WISE_COUNT + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}