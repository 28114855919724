import React, { useState } from 'react'
import { ComposedChart, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Label } from 'recharts';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
export default function PaymentsCollected() {
    const Piecolors = ['#0e5768', '#f24839', '#1eb0d2'];
    const [yearValue, setYearValue] = useState([
        '2021', '2022', '2023'
    ])
    const [yearsSelected, setYearsSelected] = useState([]);
    const [partner, setPartner] = useState('All');
    const [product, setProduct] = useState('All');
    const [rangeDpd, setRangeDpd] = useState('All');
    const [nbfcFilter, setnbfcFilter] = useState('all')

    const [data,setData] = useState({
        principal:4567,
        balance:67890,
        paymentCollected:56789,
        recoveryRatePercentage:0.35,
        paymentCollectedByRangeDPD:[
            { name: '0-30', value: 400 },
            { name: '31-60', value: 300 },
            { name: '61-90', value: 300 },
        ],
        paymentCollectedByPartnerPercentage:[
            {
                name: 'Partner_Name',
                value: 4000,
            },
            {
                name: 'Partner_Name',
                value: 3000,
            },
            {
                name: 'Partner_Name',
                value: 2000,
            }
        ],
        balancePrincipalRecoveryRateByPartner:[
            {
                name: 'Partner_Name',
                Balance: 4000,
                Principal: 4500,
                RecoveryRate: 200,
            },
            {
                name: 'Partner_Name',
                Balance: 3000,
                Principal: 3200,
                RecoveryRate: 9200,
            },
            {
                name: 'Partner_Name',
                Balance: 2000,
                Principal: 2100,
                RecoveryRate: 1220,
            }
        ],
        balancePrincipalRecoveryRateByProduct:[
            {
                name: 'Product_Name',
                Balance: 4000,
                Principal: 4500,
                RecoveryRate: 200,
            },
            {
                name: 'Product_Name',
                Balance: 3000,
                Principal: 3200,
                RecoveryRate: 9200,
            },
            {
                name: 'Product_Name',
                Balance: 2000,
                Principal: 2100,
                RecoveryRate: 1220,
            }
        ],
        paymentCollectedBalanceByYear: [
            {
                year:2020,
                data:[
                    {
                        name: 'for every month',
                        PaymentCollected: 4000,
                        Balance: 4400,
                    },
                    {
                        name: 'for every month',
                        PaymentCollected: 3000,
                        Balance: 2000,
                    },
                    {
                        name: 'for every month',
                        PaymentCollected: 2000,
                        Balance: 2100,
                    }
                ]
            },
            {
                year:2021,
                data:[
                    {
                        name: 'for every month',
                        PaymentCollected: 4000,
                        Balance: 4400,
                    },
                    {
                        name: 'for every month',
                        PaymentCollected: 3000,
                        Balance: 2000,
                    },
                    {
                        name: 'for every month',
                        PaymentCollected: 2000,
                        Balance: 2100,
                    }
                ]
            },
        ],
        tableData:[
            {
                partner: 'Partner_Name',
                PaymentCollected: '5678',
                RecoveryRate: '0.9'
            },
            {
                partner: 'Partner_Name',
                PaymentCollected: '5678',
                RecoveryRate: '0.9'
            },
            {
                partner: 'Partner_Name',
                PaymentCollected: '5678',
                RecoveryRate: '0.9'
            }
        ]

    })



    const [tableData, settableData] = useState([
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
        {
            partner: 'gvhj',
            PaymentCollected: '5678',
            RecoveryRate: '0.9'
        },
       

    ])

    const handleyearcheckbox = (value) => {
        console.log(value)
        setYearsSelected(value)
        // if (e.target.checked == true) {
        //     if (!yearsSelected.includes(e.target.value)) {
        //         yearsSelected.push(e.target.value)
        //     }
        // } else {
        //     let index = yearsSelected.indexOf(e.target.value)
        //     if (index >= 0) {
        //         yearsSelected.splice(index, 1);
        //     }
        // }
    }

    const applyFilterbtn = () =>{

    }

    const handlechange = (e, type) => {
        if (type == 'partner') {
            setPartner(e.target.value);
        }
        else if (type == 'product') {
            setProduct(e.target.value);
        }
        else if (type == 'rangedpd') {
            setRangeDpd(e.target.value);
        }
        else if (type == 'nbfc') {
            setnbfcFilter(e.target.value);
        }
    }
    const InvoicePieData = [
        { name: 'Paid Invoices', value: 400 },
        { name: 'OverDue Invoices', value: 300 },
        { name: 'Open Invoices', value: 300 },
    ];

    const PaymentByPartner = [
        {
            name: 'ASA',
            uv: 4000,
            amt: 2400,
        },
        {
            name: 'GLX',
            uv: 3000,
            amt: 2210,
        },
        {
            name: 'NDc',
            uv: 2000,
            amt: 2290,
        },
        {
            name: 'HMK',
            uv: 2780,
            amt: 2000,
        },
        {
            name: 'Tcg',
            uv: 4000,
            amt: 2400,
        },
        {
            name: 'DK',
            uv: 3000,
            amt: 2210,
        },
        {
            name: 'HN',
            uv: 2000,
            amt: 2290,
        },
        {
            name: 'FBI',
            uv: 2780,
            amt: 2000,
        },
        {
            name: 'AMG',
            uv: 3000,
            amt: 2210,
        },
        {
            name: 'TDO',
            uv: 2000,
            amt: 2290,
        },
        {
            name: 'BFc',
            uv: 2780,
            amt: 2000,
        }
    ];

    const BalancePrincipalRecoveryRateByPartner = [
        {
            name: 'ASA',
            Balance: 4000,
            Principal: 4500,
            RecoveryRate: 200,
            amt: 2400,
        },
        {
            name: 'BFc',
            Balance: 3000,
            Principal: 3200,
            RecoveryRate: 9200,
            amt: 2210,
        },
        {
            name: 'TDO',
            Balance: 2000,
            Principal: 2100,
            RecoveryRate: 1220,
            amt: 2290,
        },
        {
            name: 'AMG',
            Balance: 2780,
            Principal: 2900,
            RecoveryRate: 2200,
            amt: 2000,
        },
        {
            name: 'FBI',
            Balance: 1890,
            Principal: 2000,
            RecoveryRate: 100,
            amt: 2181,
        },
        {
            name: 'HN',
            Balance: 2390,
            Principal: 2600,
            RecoveryRate: 3200,
            amt: 2500,
        },
        {
            name: 'Tcg',
            Balance: 3490,
            Principal: 3600,
            RecoveryRate: 4200,
            amt: 2100,
        },
        {
            name: 'DK',
            Balance: 2000,
            Principal: 2200,
            RecoveryRate: 2200,
            amt: 2300,
        },

    ];

    const scatterchartdata = [
        {
            name: '1',
            PaymentCollected: 4000,
            Balance: 4400,
            amt: 2400,
        },
        {
            name: '2',
            PaymentCollected: 3000,
            Balance: 2000,
            amt: 2210,
        },
        {
            name: '3',
            PaymentCollected: 2000,
            Balance: 2100,
            amt: 2290,
        },
        {
            name: '4',
            PaymentCollected: 2780,
            Balance: 270,
            amt: 2000,
        },
        {
            name: '5',
            PaymentCollected: 1890,
            Balance: 1390,
            amt: 2181,
        },
        {
            name: '6',
            PaymentCollected: 2390,
            PaymentCollected: 4390,
            amt: 2500,
        },
        {
            name: '7',
            PaymentCollected: 3490,
            Balance: 3440,
            amt: 2100,
        },
        {
            name: '8',
            PaymentCollected: 4000,
            Balance: 4050,
            amt: 2400,
        },
        {
            name: '9',
            PaymentCollected: 3000,
            Balance: 3500,
            amt: 2210,
        },
        {
            name: '10',
            PaymentCollected: 2000,
            Balance: 2500,
            amt: 2290,
        },
        {
            name: '11',
            PaymentCollected: 2780,
            Balance: 2750,
            amt: 2000,
        },
        {
            name: '12',
            PaymentCollected: 1890,
            Balance: 1390,
            amt: 2181,
        }
    ];
    return (
        <>
            <div className="PaymentsCollected">
                <div className="filters" style={{margin:'0px'}}>
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Year</label>
                            <CustomMultiSelectCheckbox OptionValues={yearValue} onChange={handleyearcheckbox} selectedValues={yearsSelected} />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Partner</label>
                            <select className='selectBox2' value={partner} onChange={(e) => handlechange(e, 'partner')}>
                                <option value="All">All</option>
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Product</label>
                            <select className='selectBox2' value={product} onChange={(e) => handlechange(e, 'product')}>
                                <option value="All">All</option>
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Range_DPD</label>
                            <select className='selectBox2' value={rangeDpd} onChange={(e) => handlechange(e, 'rangedpd')}>
                                <option value="All">All</option>
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='selectBox2' id="nbfc" name="nbfc" value={nbfcFilter} onChange={(e) => handlechange(e, 'nbfc')} style={{ width: '100%' }} >
                                    <option value="all">All</option>
                                    <option value="yashikfinlease">Yashik Finlease</option>
                                    {/* <option value="citra">Citra</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <button className='ApplyFilter' onClick={()=>applyFilterbtn()}>Apply Filter</button>
                        </div>

                    </div>
                </div>

                <div className="toptabs" style={{marginTop:'20px'}}>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Principal</h6>
                                <h4>93.64T</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Balance</h6>
                                <h4>274.48T</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Payments Collected</h6>
                                <h4>226.40bn</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Recovery Rate</h6>
                                <h4>0.08%</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="toptabs">
                    <div className='subtabs'>
                        <h4>93.64T</h4>
                        <h6>Principal</h6>
                    </div>
                    <div className='subtabs'>
                        <h4>274.48T</h4>
                        <h6>Balance</h6>
                    </div>
                    <div className='subtabs'>
                        <h4>226.40bn</h4>
                        <h6>Payments Collected</h6>
                    </div>
                    <div className='subtabs'>
                        <h4>0.08%</h4>
                        <h6>Recovery Rate</h6>
                    </div>
                    <div className="subtabs2">
                        <h6>Year</h6>
                        {
                            yearValue?.length > 0 &&
                            yearValue?.map((value, index) => {
                                return (
                                    <div className="inputgrp" key={index}>
                                        <input type="checkbox" id={`year-${value}`} value={value} className='inputtag' onChange={(e) => handleyearcheckbox(e)} />
                                        <label htmlFor={`year-${value}`} className='labeltag' >{value}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="subtabs2">
                        <h6>Partner</h6>
                        <select className='selectBox' value={partner} onChange={(e) => handlechange(e, 'partner')}>
                            <option value="All">All</option>
                        </select>
                    </div>
                    <div className="subtabs2">
                        <h6>Product</h6>
                        <select className='selectBox' value={product} onChange={(e) => handlechange(e, 'product')}>
                            <option value="All">All</option>
                        </select>
                    </div>
                    <div className="subtabs2">
                        <h6>Range_DPD</h6>
                        <select className='selectBox' value={rangeDpd} onChange={(e) => handlechange(e, 'rangedpd')}>
                            <option value="All">All</option>
                        </select>
                    </div>
                </div> */}

                <div className="graphsSection">
                    <div className="row">
                        <div className=" col-md-6 col-xs-12 newPadding newmargin20">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Payments Collected By Range_DPD</h5>
                                </div>
                                <div className="chart" style={{ height: '315px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart
                                            width={150}
                                            height={150}
                                        >
                                            <Pie data={InvoicePieData} dataKey="value" cx="50%" cy="50%" outerRadius={120} innerRadius={60} cornerRadius={5} fill="#82ca9d"
                                                label={({ value, name, ...otherData }) => <CustomPieLabel value={value} name={name} otherData={otherData} />}
                                            >
                                                {InvoicePieData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={Piecolors[index % Piecolors.length]} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="customLegend">
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#0e5768' }}></div>
                                        <h6>1080+</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#f24839' }}></div>
                                        <h6>90-180</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#1eb0d2' }}></div>
                                        <h6>180-270</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-6 col-xs-12  newPadding newmargin20">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>% Payments Collected By Employee & Partner</h5>
                                </div>
                                <div className="chart" style={{ height: '331px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={560}
                                            height={300}
                                            data={PaymentByPartner}
                                            barSize={15}
                                            layout="vertical"
                                            margin={{
                                                top: 20, right: 60, left: -5, bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray={3} horizontal={false} />
                                            <XAxis type="number" axisLine={false} />
                                            <YAxis type="category" dataKey="name" axisLine={false} />
                                            <Tooltip />
                                            <Bar type="monotone" dataKey="uv" fill="#3b68a9" label={{ position: 'right' }} radius={[0,5,5,0]} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className=" col-md-6 col-xs-12 newPadding newmargin20">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Balance, Principal & Recovery Rate By Partner</h5>
                                </div>
                                <div className="customLegend" style={{margin:'20px 0px'}}>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#0e5768' }}></div>
                                        <h6>Balance</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#1eb0d2' }}></div>
                                        <h6>Principal</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#f24839' }}></div>
                                        {/* <div className="shape" style={{ backgroundColor: '#ecd52c' }}></div> */}
                                        <h6>Recovery Rate</h6>
                                    </div>
                                </div>
                                <div className="chart" style={{ height: '322px' }}>
                                {/* <div className="chart" style={{ height: '315px' }}> */}
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={300}
                                            data={BalancePrincipalRecoveryRateByPartner}
                                            barSize={15}
                                            margin={{
                                                top: 20,
                                                right: -5,
                                                left: -5,
                                                bottom: 2,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis dataKey="name" />
                                            <YAxis yAxisId="left" orientation="left" axisLine={false} />
                                            <YAxis yAxisId="right" orientation="right" axisLine={false} />
                                            <Tooltip />
                                            <Bar yAxisId="left" dataKey="Balance" fill="#0e5768" radius={[5,5,0,0]}/>
                                            <Bar yAxisId="right" dataKey="Principal" fill="#1eb0d2" radius={[5,5,0,0]}/>
                                            <Scatter yAxisId="left" dataKey="RecoveryRate" fill="#ecd52c" strokeWidth={2} line shape="dot" />
                                            {/* <Scatter yAxisId="left" dataKey="RecoveryRate" fill="#ecd52c" strokeWidth={2} line shape="dot" /> */}
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-6 col-xs-12 newPadding newmargin20 ">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Balance, Principal & Recovery Rate By Product</h5>
                                </div>
                                <div className="customLegend" style={{margin:'20px 0px'}}>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#0e5768' }}></div>
                                        <h6>Balance</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#1eb0d2' }}></div>
                                        <h6>Principal</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#f24839' }}></div>
                                        {/* <div className="shape" style={{ backgroundColor: '#ecd52c' }}></div> */}
                                        <h6>Recovery Rate</h6>
                                    </div>
                                </div>
                                <div className="chart" style={{ height: '322px' }}>
                                {/* <div className="chart" style={{ height: '352px' }}> */}
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={300}
                                            data={BalancePrincipalRecoveryRateByPartner}
                                            barSize={15}
                                            margin={{
                                                top: 20,
                                                right: -5,
                                                left: -5,
                                                bottom: 2,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis dataKey="name" />
                                            <YAxis yAxisId="left" orientation="left" axisLine={false} />
                                            <YAxis yAxisId="right" orientation="right" axisLine={false} />
                                            <Tooltip />
                                            <Bar yAxisId="left" dataKey="Balance" fill="#0e5768" radius={[5,5,0,0]}/>
                                            <Bar yAxisId="right" dataKey="Principal" fill="#1eb0d2" radius={[5,5,0,0]}/>
                                            <Scatter yAxisId="left" dataKey="RecoveryRate" fill="#ecd52c" strokeWidth={2} line shape="dot" />
                                            {/* <Scatter yAxisId="left" dataKey="RecoveryRate" fill="#ecd52c" strokeWidth={2} line shape="dot" /> */}
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className=" col-md-6 col-xs-12 newPadding newmargin20" style={{ overflowY: 'auto', height: '460px' }}>
                            <div className="row">
                                <div className="col-12" style={{ padding: '0px 15px' }}>
                                    <div className="graphbox">
                                        <div className="heading">
                                            <h5>Payment Collected & Balance By Year 2020 </h5>
                                        </div>
                                        <div className="customLegend" style={{margin:'20px 0px'}}>
                                            <div className="box">
                                                <div className="shape" style={{ backgroundColor: '#305fa2' }}></div>
                                                <h6>Payment Collected</h6>
                                            </div>
                                            <div className="box">
                                                <div className="shape" style={{ backgroundColor: '#f24839' }}></div>
                                                <h6>Balance</h6>
                                            </div>
                                        </div>
                                        <div className="chart" style={{ height: yearsSelected?.length <= 1 ? '322px' : '94px' }}>
                                        {/* <div className="chart" style={{ height: yearsSelected?.length <= 1 ? '352px' : '124px' }}> */}
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ScatterChart
                                                    width={560}
                                                    height={300}
                                                    data={scatterchartdata}
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        bottom: 20,
                                                        left: 0,
                                                    }}
                                                >
                                                    <CartesianGrid vertical={false} />
                                                    <XAxis dataKey="name" axisLine={false} />
                                                    <YAxis yAxisId="left" orientation="left" axisLine={false} />
                                                    <YAxis yAxisId="right" orientation="right" axisLine={false} />
                                                    <Tooltip />
                                                    <Scatter yAxisId="left" type="monotone" dataKey="PaymentCollected" fill="#305fa2" line />
                                                    <Scatter yAxisId="right" type="monotone" dataKey="Balance" fill="#f24839" line />
                                                </ScatterChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-6 col-xs-12 newPadding newmargin20">
                            <div className="graphbox" style={{ height: '434px',padding:'0px' }}>
                                <div className="tables" style={{marginTop:'6px'}}>
                                    {
                                        tableData?.length > 0 ?
                                            <div className="phocket-table-new newTable" style={{ height:'auto',maxHeight: '428px', overflowX: 'hidden' }}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }}>Partner</th>
                                                            <th style={{ textAlign: 'center' }}>Payment Collected</th>
                                                            <th style={{ textAlign: 'center' }}>Recovery Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((data, index) => (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: 'center' }}>{data.partner}</td>
                                                                <td style={{ textAlign: 'center' }}>{data.PaymentCollected}</td>
                                                                <td style={{ textAlign: 'center' }}>{data.RecoveryRate + '%'}</td>
                                                            </tr>
                                                        ))}
                                                       
                                                    </tbody>
                                                    <thead style={{bottom:'0'}}>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }}>Total</th>
                                                            <th style={{ textAlign: 'center' }}>34567890</th>
                                                            <th style={{ textAlign: 'center' }}>0.6%
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CustomPieLabel = ({ value, name, otherData }) => {
    const RADIAN = Math.PI / 180;
    const radius = otherData.innerRadius + (otherData.outerRadius - otherData.innerRadius) * 0.5;
    const newx = otherData.cx + radius * Math.cos(-otherData.midAngle * RADIAN);
    const newy = otherData.cy + radius * Math.sin(-otherData.midAngle * RADIAN);

    const labelRadius = otherData.outerRadius * 1.2;
    const labelX = otherData.cx + labelRadius * Math.cos(-otherData.midAngle * RADIAN);
    const labelY = otherData.cy + labelRadius * Math.sin(-otherData.midAngle * RADIAN);

    const horizontalLabelX = otherData.cx + (labelRadius + 20) * Math.cos(-otherData.midAngle * RADIAN);
    const horizontalLabelY = labelY;

    const isLeftSide = labelX < otherData.cx;
    return (
        <>
            <path
                d={`M${newx},${newy} L${labelX},${labelY} L${horizontalLabelX},${horizontalLabelY}`}
                stroke="#000"
                fill="none"
            />
            <text
                x={isLeftSide ? horizontalLabelX - 10 : horizontalLabelX + 10}
                y={horizontalLabelY}
                fill="#000"
                textAnchor={isLeftSide ? 'end' : 'start'}
                dominantBaseline="central"
                style={{ fontSize: '16px' }}
            >
                {value}
            </text>
        </>
    );
};

const CustomMultiSelectCheckbox = ({ OptionValues, onChange, selectedValues }) => {
    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 6;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        if (onChange) {
            onChange(value);
        }
    };

    const generateLabel = () => {
        if (selectedValues.length > 0) {
            const selectedStatuses = OptionValues.filter(optionvalue => selectedValues.includes(optionvalue)).map(optionvalue => optionvalue);
            return selectedStatuses.join(', ');
        }
        return 'Select Year';
    };
    return (
        <>
            <FormControl sx={{ width: '-webkit-fill-available', backgroundColor: '#DBE9FF', borderRadius: '6px', height: '40px' }}>
                <label style={{ display:'flex',alignItems:'center',fontSize: '14px', fontWeight: '400', position: 'absolute', zIndex: '1', top: '4%', left: '2%', width: '80%', height: '35px', overflowY: 'hidden', backgroundColor: '#DBE9FF', borderRadius: '6px' }}>{generateLabel()}</label>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedValues}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    style={{ height: '41.5px', backgroundColor: '#DBE9FF' }}
                >


                    {OptionValues.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedValues.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}